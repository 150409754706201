import React from "react";
import logo from "./img/logo.png";
import simon from "./img/simon.jpg";
import chato from "./img/chato.jpg";
import { Link } from "react-router-dom";
import { Footer } from "./Footer";

export function Home() {
  return (
    <div className="home">
      <div className="head">
        <img alt="ProSportsLab" src={logo} />
        <div className="subtitle">
          Skyrocket your golf performance with <b>accessible</b> launch monitor
          technology
        </div>
      </div>
      <div className="container">
        <div className="mt-4" />
        <h1 className="center">Over ons</h1>
        <div className="about">
          <img alt="Simon Van Damme" src={simon} />
          <div>
            Simon Van Damme (23) is naast een <b>business freak</b>, ook een
            muzikaal talent. De Ocean Etude van Chopin aanleren? Geen probleem.
            Met zijn <b>creativiteit en doorzettingsvermogen</b> zoekt hij een
            oplossing voor elk vraagstuk.
            <br />
            <br />
            Bij ProSportsLab houdt hij zich dan ook dagelijks bezig met het
            uitwerken van hun <b>business case</b>, maar je kan hem ook
            aantreffen als pianist bij een jazz trio én een 70’s cover band! Je
            kan hem samen met Chato De Veirman terugvinden <b> op het golfterrein</b>.
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
