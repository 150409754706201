import React from "react";

export function Footer() {
  return (
    <div className="footer">
      <div>ProSportsLab BV</div>
      <div>BE0761.829.585</div>
      <a href="mailto:info@prosportslab.be">info@prosportslab.be</a>
    </div>
  );
}
